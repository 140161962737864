import { COUNTRY } from '../../utils/constants';

export default {
    namespaced: true,

    state: () => ({
        quickLinks: {
            titleTranslate: 'layouts_footer_col_links_heading',
            data: [
                {
                    textTranslate: 'layouts_main_0009',
                    to: '/about',
                },
                {
                    textTranslate: 'layouts_main_0010',
                    to: '/contact',
                },
                {
                    textTranslate: 'layouts_main_0011',
                    to: '/legal',
                    break: true,
                },
                {
                    textTranslate: 'layouts_footer_col_links_link_confidentiality',
                    to: '/legal#privacy-policy',
                    rel: 'nofollow',
                },
                {
                    textTranslate: 'footer_quicklink_together',
                    href: 'https://partners.vivre.eu/signup',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                {
                    countries: [COUNTRY.RO.ID],
                    textTranslate: 'layouts_main_footer_ir',
                    href: 'https://www.vivre.eu/ir/',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                {
                    countries: [COUNTRY.RO.ID],
                    text: 'ANPC',
                    href: 'http://www.anpc.ro/',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                {
                    textTranslate: 'layouts_main_odr_link',
                    href: 'https://ec.europa.eu/consumers/odr',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                {
                    countries: [COUNTRY.RO.ID],
                    textTranslate: 'layouts_main_anpc_sal',
                    href: 'https://anpc.ro/ce-este-sal/',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                {
                    textTranslate: 'layouts_footer_faq',
                    href: '/info/faq/',
                },
                {
                    textTranslate: 'layouts_blog',
                    href: '/blog/',
                },
                {
                    countries: [COUNTRY.RO.ID],
                    text: 'BESTJOBS - Gaseste un job care te face sa traiesti cu pasiune!',
                    href: 'https://www.bestjobs.eu',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                {
                    countries: [COUNTRY.RO.ID, COUNTRY.MD.ID],
                    text: 'hip.car - Rent a car or call a taxi',
                    href: 'https://www.hip.car/',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
            ],
        },
        legalInfo: {
            [COUNTRY.RO.ID]: {
                sol: {
                    textTranslate: 'layouts_main_odr_link',
                    image: 'https://s9.vivre.eu/assets/images/anpc-sol-sm.png',
                    href: 'https://ec.europa.eu/consumers/odr',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
                sal: {
                    textTranslate: 'layouts_main_anpc_sal',
                    image: 'https://s9.vivre.eu/assets/images/anpc-sal-sm.png',
                    href: 'https://anpc.ro/ce-este-sal/',
                    target: '_blank',
                    rel: 'nofollow noopener',
                },
            },
        },
        socialProfilesLinks: {
            [COUNTRY.RO.ID]: {
                facebook: 'http://www.facebook.com/vivrero',
                instagram: 'https://www.instagram.com/vivre_ro/',
                youtube: 'https://www.youtube.com/c/Vivreromania',
                tiktok: 'https://www.tiktok.com/@vivrevibes',
            },
            [COUNTRY.BG.ID]: {
                facebook: 'https://www.facebook.com/vivrebg/',
                instagram: 'https://www.instagram.com/vivre.bg/',
                youtube: 'https://www.youtube.com/channel/UCO0YWMsSWLDGnzhm1zxwqjQ',
                pinterest: 'https://www.pinterest.com/vivrebg/',
            },
            [COUNTRY.HU.ID]: {
                facebook: 'https://www.facebook.com/vivrehu/',
                instagram: 'https://www.instagram.com/vivre_hu/',
                youtube: 'https://www.youtube.com/channel/UCYanmkjYWF5DfD1cyaZeMpQ',
                pinterest: 'https://www.pinterest.com/vivre_hu/',
            },
            [COUNTRY.HR.ID]: {
                facebook: 'https://www.facebook.com/vivrehr/',
                instagram: 'https://www.instagram.com/vivre_hr/',
                youtube: 'https://www.youtube.com/channel/UC-TjZi3tnYQuRYZBsZ1AGxg/',
                pinterest: 'https://www.pinterest.com/vivrehr/',
            },
            [COUNTRY.PL.ID]: {
                facebook: 'https://www.facebook.com/vivrepl/',
                instagram: 'https://www.instagram.com/vivrehome.pl/',
                youtube: 'https://www.youtube.com/channel/UCzyTQlLGvSBoSwK2O1dd7lg',
                pinterest: 'https://www.pinterest.com/vivrehomepl/',
            },
            [COUNTRY.SI.ID]: {
                facebook: 'https://www.facebook.com/vivresi/',
                instagram: 'https://www.instagram.com/vivre.si/',
                youtube: 'https://www.youtube.com/channel/UCed8lRgYvkWB3LByqfB0tCg',
                pinterest: 'https://www.pinterest.com/vivresi/',
            },
            [COUNTRY.SK.ID]: {
                facebook: 'https://www.facebook.com/vivresk/',
                instagram: 'https://www.instagram.com/vivrehome.sk/',
                youtube: 'https://www.youtube.com/channel/UCJQHvyP98IvPce39yxuny6Q',
                pinterest: 'https://www.pinterest.com/vivrehomesk/',
            },
            [COUNTRY.CZ.ID]: {
                facebook: 'https://www.facebook.com/vivrecz/',
                instagram: 'https://www.instagram.com/vivre.cz/',
                youtube: 'https://www.youtube.com/channel/UCJrRVJx1LtPjcrcLiVET7vA',
                pinterest: 'https://www.pinterest.com/vivrecz/',
            },
            [COUNTRY.GR.ID]: {
                facebook: 'https://www.facebook.com/vivregr/',
                instagram: 'https://www.instagram.com/vivre.gr/',
                youtube: 'https://www.youtube.com/channel/UCKle9QVYQzQHcCcex1DSuuQ',
                pinterest: 'https://www.pinterest.com/vivregr/',
            },
            [COUNTRY.MD.ID]: {
                facebook: 'http://www.facebook.com/vivremd',
                instagram: 'https://www.instagram.com/vivre_md/',
                youtube: 'https://www.youtube.com/c/Vivreromania',
                tiktok: 'https://www.tiktok.com/@vivrevibes',
            },
        },
    }),

    getters: {
        quickLinks: (state, getters, rootState) => {
            const currentCountry = rootState.params.country.id;

            return state.quickLinks.data
                .filter((link) => {
                    const isLinkValid = link && typeof link !== 'undefined';
                    if (!isLinkValid) return false;

                    const linkHasValidCountry = typeof link.countries === 'undefined' || link.countries.includes(currentCountry);
                    const linkHasUrl = link.to || link.href;
                    return linkHasValidCountry && linkHasUrl;
                })
                .map((link) => {
                    if (typeof link.href === 'object') Object.assign(link, { href: link.href[currentCountry] });
                    return link;
                });
        },
    },
};
